.container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    /* height: 55.7vh; */
    height: 80dvh;
}

section {
    /* height: 55.7vh; */
    height: 80dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
}

.giftxchange {
    background-color: #24C0A8;
}

.giftxchange h5, .giftxchange h6, .giftxchange button {
    color: #FFF;
    text-shadow: 1px 1px 4px #000;
}

.santasmailbox {
    background-color: #FFF2DE;
}

.santasmailbox h5, .santasmailbox button {
    color: #CD9770;
}

.santasmailbox h6 {
    color: #09B0A6;
}

.smartvads {
    background-color: #0B0C10;
}

.smartvads h5, .smartvads button {
    color: #66FCF1;
}

