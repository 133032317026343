@font-face {
    font-family: 'EurostileBold';
    src: url('./EurostileBold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NordicAlternativeRegular';
    src: url('./Nordic-Alternative-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OkojoProBold';
    src: url('./OkojoPro-Bold.otf');
    font-weight: normal;
    font-style: normal;
}